<template>
  <div>
    <b-row>
      <b-col
        v-if="IS_PRODUCTION || IS_CRM_PORTAL"
        cols="12"
        lg="12"
      >
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              class="bg-white position-relative overflow-hidden"
            >
              <template #header>
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <h4 class="mb-0">
                    Test Account Balance
                  </h4>
                  <b-button
                    size="sm"
                    @click="getTestPayoutPoolBalance"
                  >
                    Refresh
                  </b-button>
                </div>
              </template>

              <b-alert
                variant="danger"
                class="px-2 py-1"
                :show="TestPayoutPoolBalance.prefund_balance < 10"
              >
                <h5 class="text-danger font-weight-bolder">
                  Payout Pool Balance
                </h5>
                The test account payout balance is low. Please contact admin for account balance replenishment.
              </b-alert>

              <div class="mt-2 d-flex justify-content-between">

                <div>
                  <h5>Test Account</h5>
                  <h3 class="mb-0">
                    Cloudeo Enterprise
                  </h3>
                  <h6>LP-9290CC13-MM</h6>
                </div>

                <div class="text-center">
                  <h5>Payout Balance</h5>
                  <h3><vue-numeric
                    :value="TestPayoutPoolBalance.prefund_balance"
                    :precision="2"
                    separator=","
                    read-only
                    :currency="'MYR'"
                  /></h3>
                </div>

                <div class="text-center">
                  <h5>Txn Pool Balance</h5>
                  <h3><vue-numeric
                    :value="TestPayoutPoolBalance.transaction_balance.value"
                    :precision="2"
                    separator=","
                    read-only
                    :currency="'MYR'"
                  /></h3>
                </div>
              </div>

            </b-card>
          </b-col>
        </b-row>

        <h3 class="mb-2">
          Select Receiving Bank
        </h3>
        <b-row>
          <b-col
            v-for="(bank, key) in PayoutList"
            :key="`bank-${key}`"
            cols="12"
            lg="2"
          >

            <b-card
              class="bg-white position-relative overflow-hidden"
              :border-variant="selectedBank.payout_service_id === bank.payout_service_id? 'primary': ''"
              @click="selectReceivingBank(bank)"
            >

              <b-img
                :src="`/img/payout/${bank.unique_reference}.png`"
                height="80"
              />

              <h6>
                {{ bank.name }}
              </h6>

              <div
                v-if="bank.isHaveAccount"
                class="position-absolute position-right-0 position-top-0"
              >
                <div style="height: 100px; width: 100px;text-align: center;">
                  <h6
                    class="bg-secondary text-white"
                    style=" padding: 5px; border-bottom-left-radius: 8px; font-size: 0.8rem"
                  >
                    Account Available
                  </h6>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        v-else
        cols="12"
        lg="4"
      >
        <b-card
          class="bg-white position-relative overflow-hidden"
        >
          <template #header>
            <div class="d-flex w-100 justify-content-between align-items-center">
              <h4 class="mb-0">
                Test Account Balance
              </h4>

            </div>
          </template>

          <b-alert
            variant="danger"
            class="px-2 py-1"
            show
          >
            <h5 class="text-danger font-weight-bolder">
              Payout Pool Balance
            </h5>
            The test account feature is only available on PRODUCTION
          </b-alert>

        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="createPayoutModal"
      ref="createPayoutModalRef"
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      :title="'Payout'"
      :size="'md'"
      hide-footer
      @hidden="closeModal"
    >
      <h5 class="mt-1 mb-2">
        Payout Via <strong>{{ selectedBank.name }}</strong>
      </h5>

      <template v-if="getAccountOpts(selectedBank.payout_service_id).length> 0">
        <div class="mb-3">
          <h5><strong>Use Preset Account</strong></h5>
          <template v-for="(acc, i) in getAccountOpts(selectedBank.payout_service_id)">
            <div
              :key="`accPreset-${i}`"
              class="p-2 mb-1 border"
              @click="selectedRecipient(acc)"
            >
              <h5 class="font-weight-bolder">
                {{ acc.name }}
              </h5>
              <h6 class="mb-0">
                {{ acc.accountNo }}
              </h6>
            </div>
          </template>

          <div class="d-flex">
            <b-button @click="form.amount = 1">
              RM 1.00
            </b-button>
            <b-button
              class="ml-1"
              @click="form.amount = 0.01"
            >
              RM 0.01
            </b-button>
          </div>
        </div>

        <h5 class="mb-1">
          <strong>or Enter Details</strong>
        </h5>
        <b-form-group label="Name">
          <b-form-input v-model="form.name" />
        </b-form-group>
        <b-form-group label="Account No">
          <b-form-input v-model="form.accountNo" />
        </b-form-group>
        <!--        <b-form-group label="Payout Service ID">-->
        <!--          <b-form-input v-model="form.payout_service_id" />-->
        <!--        </b-form-group>-->
        <b-form-group label="Amount">
          <b-form-input
            v-model="form.amount"
            disabled
          />
        </b-form-group>

      </template>
      <template v-else>

        <div
          class="p-2 mb-3 border"
        >
          No Preset Account
        </div>

        <h5 class="mb-1">
          Enter Details
        </h5>

        <b-form-group label="Name">
          <b-form-input v-model="form.name" />
        </b-form-group>
        <b-form-group label="Account No">
          <b-form-input v-model="form.accountNo" />
        </b-form-group>
        <!--        <b-form-group label="Payout Service ID">-->
        <!--          <b-form-input v-model="form.payout_service_id" />-->
        <!--        </b-form-group>-->
        <b-form-group label="Amount">
          <b-form-input
            v-model="form.amount"
            :disabled="getAccountOpts(selectedBank.payout_service_id).length> 0"
          />
        </b-form-group>

      </template>

      <div class="mt-3 mb-1 d-flex justify-content-end">
        <b-button @click="clearForm">
          Clear
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="makePayout"
        >
          Make Test Payout
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>

import { toRaw } from '@vue/composition-api'

export default {
  components: {},
  data() {
    return {
      selectedAccountId: null,
      selectedBank: [],
      form: {
        amount: '',
        name: '',
        payout_service_id: '',
        accountNo: '',
      },
      payoutPoolBalance: {

      },
    }
  },
  computed: {
    TestPayoutPoolBalance() {
      const list = this.$store.getters['general/getTestPayoutPoolBalanceDetail']

      return list
    },
    leanxPayable() {
      if (this.IS_PRODUCTION || this.IS_CRM_PORTAL) {
        return [
          {
            payout_service_id: 77,
            name: 'Maybank Berhad',
            payoutDetail: [
              { name: 'Azrul Anuar', accountNo: '114085826509', amount: 0.01 },
              { name: 'Nizam Yaakop', accountNo: '162441057836', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 122,
            name: 'TNG Digital (TouchNGo)',
            payoutDetail: [
              { name: 'Nizam Yaakop', accountNo: '102119927710', amount: 1.00 },
              { name: 'Wira Azharan', accountNo: '150979915955', amount: 1.00 },
            ],
          },
          {
            payout_service_id: 134,
            name: 'Axiata Digital Ecode Sdn Bhd - Boost',
            payoutDetail: [
              { name: 'Nizam Yaakop', accountNo: '100288835796', amount: 1.00 },
            ],
          },
          {
            payout_service_id: 93,
            name: 'Public Bank Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '4957262926', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 150,
            name: 'AEON Bank (M) Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '9988038104444', amount: 1.00 },
            ],
          },
          {
            payout_service_id: 117,
            name: 'United Overseas Bank Berhad (UOB) ',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '9003197472', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 13,
            name: 'Bank Islam Malaysia Berhad ',
            payoutDetail: [
              { name: 'Syahirah Zaki', accountNo: '13035020615236', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 138,
            name: 'Shopee',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '18385898602053', amount: 1.00 },
            ],
          },
          {
            payout_service_id: 101,
            name: 'RHB Bank Berhad',
            payoutDetail: [
              { name: 'Azrul Anuar', accountNo: '11232100135692', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 118,
            name: 'GX Bank Berhad',
            isEwallet: true,
            payoutDetail: [
              { name: 'Nizam Yaakop', accountNo: '8888002745883', amount: 1 },
            ],
          },
          {
            payout_service_id: 130,
            name: 'BigPay Malaysia Sdn Bhd',
            isEwallet: true,
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '84895481878787', amount: 1 },
              { name: 'Nizam Yaakop', accountNo: '88979459008407', amount: 1 },
            ],
          },
          {
            payout_service_id: 49,
            name: 'CIMB Bank Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '7030427367', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 89,
            name: 'OCBC Bank Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '7902326888', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 41,
            name: 'Bank Simpanan Nasional Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '0800241100054920', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 65,
            name: 'Hong Leong Bank Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '39501116188', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 21,
            name: 'Bank Kerjasama Rakyat Malaysia Berhad',
            payoutDetail: [
              { name: 'Wira Azharan', accountNo: '2208601760', amount: 0.01 },
            ],
          },
          {
            payout_service_id: 81,
            name: 'Alliance Bank Malaysia Berhad',
            payoutDetail: [
              { name: 'Nizam Yaakop', accountNo: '620470013004227', amount: 0.01 },
            ],
          },
        ]
      } return []
    },
    PayoutList() {
      const list = this.$store.getters['general/getPayoutList']

      if (list.data) {
        const payoutList = list.data[0].SWITCH_PAYOUT_WEBPAY
        const { leanxPayable } = this

        if (this.IS_PRODUCTION || this.IS_CRM_PORTAL) {
          payoutList.forEach((payout, index) => {
            leanxPayable.forEach((payable, index2) => {
              if (payout.payout_service_id === payable.payout_service_id) {
                console.log(payout.name, payout.payout_service_id === payable.payout_service_id)
                payout.isHaveAccount = true
                payout.weight = index + 1
              } else {
                payout.weight = index + 99
              }
            })
          })
        }

        return payoutList
      }
      return list
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Helper' },
      { title: 'Withdrawal Tester' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    if (this.IS_PRODUCTION || this.IS_CRM_PORTAL) {
      await this.$store.dispatch('general/getOnboardPayoutList')
      await this.getTestPayoutPoolBalance()
    }
  },
  methods: {
    async getTestPayoutPoolBalance() {
      await this.$store.dispatch('general/getTesterPayoutPoolBalance')
    },
    getAccountOpts() {
      const x = this.leanxPayable.find(item => item.payout_service_id === this.selectedBank.payout_service_id)
      if (x) {
        return x.payoutDetail
      }
      return []
    },
    selectedRecipient(acc) {
      console.log(JSON.parse(JSON.stringify(acc)))
      const sr = JSON.parse(JSON.stringify(acc))
      this.form = { ...sr, payout_service_id: this.selectedBank.payout_service_id }
    },
    closeModal() {
      this.selectedBank = []
      this.clearForm()
      this.$refs.createPayoutModalRef.hide()
    },
    makePayout() {
      const payload = {
        ...this.form,
        account_no: this.form.accountNo,
        payout_service_id: this.selectedBank.payout_service_id,
      }
      this.$store.dispatch('general/triggerPayout', payload).then(resPayout => {
        if (resPayout.data.response_code === 2100) {
          if (resPayout.data.data.response.response_code === 2000) {
            this.$swal.fire({
              title: 'Success',
              text: 'Payout test successful',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${resPayout.data.data.response.response_code}`,
              text: resPayout.data.data.response.description,
              icon: 'error',
            })
          }
        }
      })
    },
    clearForm() {
      this.form = {
        amount: '',
        name: '',
        accountNo: '',
      }
    },
    selectAccount(account) {
      this.selectedAccountId = null
      this.selectedAccountId = account
      console.log(account)
    },
    selectReceivingBank(bank) {
      this.selectedBank = null
      this.selectedBank = bank
      this.form.payout_service_id = this.selectedBank.payout_service_id

      this.$refs.createPayoutModalRef.show()

      console.log(bank)
    },
  },
}
</script>

<style>

</style>
